import gql from "graphql-tag";
import {
  ACTIVE_USER,
  ADD_STORE_MUTATION,
  GET_STORE,
  GET_STORE_INTERRUPTIONS,
  UPDATE_STORE_MUTATION,
  CHANAGE_LANGAGE,
  SEND_ACCESS,
} from "graphql/tags";
import { useMutation, useQuery } from "react-apollo";

const FragmentStores = gql`
  fragment nodes on CommandElasticStore {
    nodes {
      id
      name
      gerantName
      address
      photoUrl
      location {
        latitude
        longitude
      }
      rating
      noticeCount
      profile {
        id
        email
        isBlocked
      }
      createdAt
    }
  }
`;
export const useMutationLangage = () => {
  const [updateLangage] = useMutation(CHANAGE_LANGAGE);
  function updateCurrentLangage({ langage }) {
    return updateLangage({
      variables: {
        langage,
      },
    });
  }
  return { updateCurrentLangage };
};
export const ADD_STRIPE = gql`
  mutation createLinkedAccountStore($id: String) {
    createLinkedAccountStore(id: $id)
  }
`;
export const EDIT_STRIPE = gql`
  mutation updateLinkedAccountStore($id: String) {
    updateLinkedAccountStore(id: $id)
  }
`;
export const useMutationStore = () => {
  const [addStore] = useMutation(ADD_STORE_MUTATION);
  const [updateStoreMutation] = useMutation(UPDATE_STORE_MUTATION);
  function addNewStore({ input }) {
    return addStore({
      variables: {
        input,
      },
      update: (cache, { data }) => {
        const old = cache.readFragment({
          fragment: FragmentStores,
          id: "CommandElasticStore",
        });

        cache.writeFragment({
          fragment: FragmentStores,
          id: "CommandElasticStore",
        });
      },
    });
  }
  function updateStore({ input }) {
    return updateStoreMutation({
      variables: {
        input,
      },
    });
  }
  return { addNewStore, updateStore };
};
export const useMutationActiveUser = () => {
  const FRAGMENT = gql`
    fragment store on Florist {
      id
      name
      gerantName
      address
      location {
        latitude
        longitude
      }
      profile {
        id
        email
        isBlocked
      }
      createdAt
    }
  `;
  const FRAGMENT_CLIENT = gql`
    fragment client on Client {
      id
      firstName
      lastName
      countOrders
      totalExpenses
      profile {
        id
        email
        isBlocked
      }
      createdAt
    }
  `;
  const [activeUser] = useMutation(ACTIVE_USER);
  function activeThisUser(userId, storeId = null) {
    return new Promise((resolve, reject) => {
      activeUser({
        variables: {
          userId,
        },
        update: (cache, {}) => {
          if (storeId) {
            const store = cache.readFragment({
              id: storeId,
              fragment: FRAGMENT,
            });
            cache.writeFragment({
              id: storeId,
              fragment: FRAGMENT,
              data: {
                ...store,
                profile: {
                  ...store?.profile,
                  isBlocked: !store?.profile?.isBlocked,
                },
              },
            });
          }
        },
      })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  }
  function activeThisClient(userId, clientId = null) {
    return new Promise((resolve, reject) => {
      activeUser({
        variables: {
          userId,
        },
        update: (cache, {}) => {
          if (clientId) {
            const client = cache.readFragment({
              id: clientId,
              fragment: FRAGMENT,
            });
            cache.writeFragment({
              id: clientId,
              fragment: FRAGMENT,
              data: {
                ...client,
                profile: {
                  ...client?.profile,
                  isBlocked: !client?.profile?.isBlocked,
                },
              },
            });
          }
        },
      })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  }
  return { activeThisUser, activeThisClient };
};
export const useQueryStore = (id) => {
  const { data, ...others } = useQuery(GET_STORE, {
    variables: {
      id,
    },
    fetchPolicy: "no-cache",
  });
  return {
    data: data?.getFlorist,
    ...others,
  };
};

export const useQueryStoreInterruptions = ({
  professional,
  page = 0,
  limit = 6,
}) => {
  const { data, ...others } = useQuery(GET_STORE_INTERRUPTIONS, {
    variables: {
      professional,
      skip: page * limit,
      limit,
    },
  });
  return {
    storeInterruptions: data?.storeInterruptions?.nodes,
    lastPage: data?.storeInterruptions?.lastPage,
    ...others,
  };
};
export const useMutationDeleteStores = () => {
  const DELETE_TAG = gql`
    mutation deleteStores($stores: [String]) {
      deleteStores(stores: $stores)
    }
  `;
  const [deleteStoress] = useMutation(DELETE_TAG);
  function deleteStores({ stores }) {
    return deleteStoress({
      variables: {
        stores,
      },
    });
  }
  return { deleteStores };
};
export const useMutationSendAcces = () => {
  const [sendWelcomeMail] = useMutation(SEND_ACCESS);
  function sendAccesMail({ id }) {
    return sendWelcomeMail({
      variables: {
        id,
      },
    });
  }
  return { sendAccesMail };
};
